<template>
    <app-page v-if="currentContract"
              title="Consignes"
              :back="() => { $router.push({ name: 'documents' }) }">
        <section>
            <center-container col-class="col-xs-no-gutter bg-white">
                <Consigne v-for="consigne in currentContract.profiles" :consigne="consigne"
                          :key="consigne.idactivity_profile"/>
            </center-container>
        </section>
    </app-page>
</template>

<script>

import Consigne from "./Consigne";
import AppPage from '@/components/layout/AppPage'
import CenterContainer from '@/components/layout/CenterContainer'
import Consignes from "@/components/pages/contrats/consignes/Consignes";

export default {
    components: {Consigne, CenterContainer, AppPage, Consignes},
    name: "Consignes",
    data() {
        return {
            currentContract: []
        }
    },
    mounted() {
        this.currentContract = this.$route.params.currentContract
    },
    methods: {}
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus, button.headercontrat:focus {
    box-shadow: none !important;
    color: black;
}
</style>
